import { RouteComponentProps, Router } from "@reach/router";
import Teaser from "./components/teaser";
//import BigBugHunt from "./components/big-bug-hunt";
//import LittleBunnings from "./components/little-bunnings";

const NotFound: React.FC<RouteComponentProps> = () => <p>Page not found</p>;

const App = () => (
  <Router className="little-bunnings-app">

    <Teaser path="/" />
    <Teaser path="/big-bug-hunt/*" />
    <Teaser path="/eggsplorer/*" />
    <Teaser path="/gingerbread-helper-hunt/*" />

    {/* <LittleBunnings path="/" /> */}

  {/* 
    <BigBugHunt path="/" />
    <BigBugHunt path="/big-bug-hunt/*" />
    <BigBugHunt path="/eggsplorer/*" />
    <BigBugHunt path="/gingerbread-helper-hunt/*" />
  */}

    <NotFound default />
  </Router>
);

export default App;
