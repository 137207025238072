import { RouteComponentProps } from "@reach/router";

const Teaser: React.FC<RouteComponentProps> = () => {
  return (
    <div className="app-overlay teaser">
      <div className="tease-img" />
    </div>
  );
};

export default Teaser;
